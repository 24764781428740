<template>
	<div>
		<vs-input v-filter-input-email
			v-validate="'required|email|min:3'"
			data-vv-validate-on="blur"
			name="email"
			:label-placeholder="$t('Email')"
			v-model="email"
			class="w-full"
		/>
		<span class="text-danger text-sm">{{ $t(errors.first('email')) }}</span>

		<vs-input
			data-vv-validate-on="blur"
			v-validate="'required|min:6'"
			type="password"
			name="password"
			:label-placeholder="$t('Password')"
			v-model="password"
			class="w-full mt-10"
		/>
		<span class="text-danger text-sm">{{ $t(errors.first('password')) }}</span>

		<div class="flex flex-wrap justify-between items-center mt-8">
			<div>
				<router-link to="/forgot-password">{{ $t('Forgot Password') }}</router-link>
			</div>
			<div>
				<vs-button
					:class="{ 'is-loading': isLoggingIn }"
					:disabled="!validateForm"
					@click="loginJWT">
					{{ $t('Login') }}
				</vs-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      isLoggingIn: false,
    };
  },
  computed: {
    ...mapGetters({
      loginError: 'loginError',
    }),
    validateForm() {
      return (
        !this.errors.any() && this.email !== '' && this.password !== ''
      );
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      // if (this.$store.state.auth.isUserLoggedIn()) {
      if (this.$store.state.isLoggedIn) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    loginJWT() {
      if (!this.checkLogin()) return;

      this.isLoggingIn = true;

      // Loading
      this.$vs.loading();

      const credentials = {
        email: this.email,
        password: this.password,
      };

      this.$store
        .dispatch('login', credentials)
        .then(() => {
          this.$vs.loading.close();
          this.isLoggingIn = false;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t('Login Error'),
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isLoggingIn = false;
        });
    },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push('/register').catch(() => {});
    },
    loginSMS() {
      if (!this.checkLogin()) return;
      this.$router.push('/login-sms').catch(() => {});
    },
    upcomingEvents() {
      if (!this.checkLogin()) return;
      this.$router.push('/upcoming-events').catch(() => {});
    },
  },
  watch: {
    loginError(message) {
      if (message && message !== '') {
        this.$vs.notify({
          title: this.$t('Login Error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
    },
  },
};
</script>
<style scoped>
	a{
		color: #8F8F8F;
	}
</style>
