<template>
	<div
		class="vx-row h-screen lg:flex w-full no-gutter"
		id="page-login"
		>
		<div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full bg-blue"
      :class="`${view === 'login' ? 'hidden lg:block' : ''}`"
      >
			<div class="flex justify-center items-center h-full w-full">
				<div>
          <enter-event-code />

          <div class="mt-20 block lg:hidden">
            <h4 class="text-white cursor-pointer" @click="setView('login')">{{ $t('Login as event host') }} </h4>
          </div>
        </div>
			</div>
		</div>
		<div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full m-4 lg:m-0"
      :class="`${view === 'code' ? 'hidden lg:block' : ''}`"
      >
			<div class="flex items-center justify-center h-full w-full">
        <div>
          <div class="lg:p-10 max-w-lg lg:max-w-md mx-auto login-wrapper w-full">
            <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3"/>
            <vx-card class="rounded-md">
              <div slot="no-body">
                <div class="vx-row no-gutter justify-center items-center">
                  <div class="vx-col w-full">
                    <div class="px-8 pt-8 login-tabs-container">
                      <vs-row vs-type="flex" vs-justify="flex-start">
                        <h4>{{ $t('Login as event host') }} </h4>
                      </vs-row>

                      <div class="mt-12">
                        <login-jwt></login-jwt>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>

            <!-- <div class="text-center mt-5">
              Haven't signed up yet? <router-link to="/register">{{ $t('Create an account') }}</router-link>
            </div> -->
          </div>

          <div class="mt-20 block lg:hidden">
            <h4 class="cursor-pointer" @click="setView('code')">{{ $t('Enter event code') }} </h4>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import EnterEventCode from '@/components/events/EnterEventCode.vue';
import LoginJwt from './LoginJWT.vue';

export default {
  components: {
    LoginJwt,
    EnterEventCode,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      view: 'code',
    };
  },
  methods: {
    registerUser() {
      this.$router.push('/register').catch(() => {});
    },
    setView(view) {
      this.view = view;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style lang="scss">
.auth-card{
	min-width: 470px;
}
.auth-mobile{
	min-width: 300px;
}
.title-auth{
	color: #4690DE;
	cursor: pointer;
}
.login-tabs-container {
	min-height: 350px;

	.con-tab {
		padding-bottom: 14px;
	}

	.con-slot-tabs {
		margin-top: 1rem;
	}
}
</style>
